import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";


// create Director
export const createDirectorAction = createAsyncThunk("createDirectorAction/user/createDirector", async (data) => {
    const response = await api.post("user/createDirector", data);
    return response.data;
  }
);


// get director detail by id

export const getDirectorDataById = createAsyncThunk('getDirectorDataById/user/getDirectorById',async ({ id, shopId }, thunkAPI) => {
    const response = await api.get(`user/getDirectorById`, {
      params: {
        directorId: id,
      },
    });
    return response.data;
  }
  );


  // edit director
export const editDirectorAction = createAsyncThunk("editDirectorAction/user/editDirector", async (data) => {
  const response = await api.put("/user/editDirector", data);
  return response.data;
}
);

// delete photos api 

export const deletePictureAction = createAsyncThunk(
  'user/deletePicture',
  async ({ type, pictureURL, userId }) => {
    try {
      const response = await api.get('/user/deletePicture', {
        params: {
          type,
          pictureURL,
          userId,
        },
      });
      return response.data; 
    } catch (error) {
      throw new Error(error.message);
    }
  }
);