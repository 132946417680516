import { createSlice } from "@reduxjs/toolkit";
import { getPlayerDataById, getPlayerListAction } from "../Action/playerAction";


const initialState = {
  loading: false,
  playerDetail: [],
  playerList: [],
  error: false,
};

const playerAuthSlice = createSlice({
  name: "playerAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlayerDataById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlayerDataById.fulfilled, (state, { payload }) => {
        state.playerDetail = payload.data
        state.loading = false;
      })
      .addCase(getPlayerDataById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(getPlayerListAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlayerListAction.fulfilled, (state, { payload }) => {
        state.playerList = payload.data
        state.loading = false;
      })
      .addCase(getPlayerListAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
    },
});


export default playerAuthSlice.reducer;
