import { createSlice } from "@reduxjs/toolkit";
import { staticDataAction, staticDirectorDataAction } from "../Action/staticAction";


const initialState = {
  loading: false,
  userStaticDetail: [],
  directorStaticDetail: [],
  error: false,
};

const staticAuthSlice = createSlice({
  name: "staticAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(staticDataAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(staticDataAction.fulfilled, (state, { payload }) => {
        state.userStaticDetail = payload.data
        state.loading = false;
      })
      .addCase(staticDataAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(staticDirectorDataAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(staticDirectorDataAction.fulfilled, (state, { payload }) => {
        state.directorStaticDetail = payload.data
        state.loading = false;
      })
      .addCase(staticDirectorDataAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
    },
});


export default staticAuthSlice.reducer;
