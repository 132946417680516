

// token 
export const getToken = () => {
    const token = localStorage.getItem("token");
    return token ? token : null;
  };

  export const today = new Date().toISOString().split('T')[0];

  export const getYesterday = () => {
   const today = new Date();
   today.setDate(today.getDate() - 1);
   return today.toISOString().split('T')[0];
 };