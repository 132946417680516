import { configureStore } from "@reduxjs/toolkit";
import userAuthReducer from "./Reducers/userSlice"
import staticAuthReducer from "./Reducers/staticSlice"
import playerAuthReducer from "./Reducers/playerSlice"
import directorAuthReducer from "./Reducers/directorSlice"

export const store = configureStore({
  reducer: {
   userAuthData : userAuthReducer,
   staticAuthData : staticAuthReducer,
   playerAuthData : playerAuthReducer,
   directorAuthData : directorAuthReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
