import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// signup for action for player
export const staticDataAction = createAsyncThunk("staticDataAction/user/staticData", async () => {
    const response = await api.get("/user/staticData?type=1");
    return response.data;
  }
);


// director data
export const staticDirectorDataAction = createAsyncThunk("staticDirectorDataAction/user/staticData", async () => {
  const response = await api.get("/user/staticData?type=2");
  return response.data;
}
);