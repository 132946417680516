import { createSlice } from "@reduxjs/toolkit";
import { getProfileDetail, getUserUniqueId } from "../Action/auth";


const initialState = {
  loading: false,
  userUniqueId: [],
  getProfileData: [],
  error: false,
};

const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserUniqueId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserUniqueId.fulfilled, (state, { payload }) => {
        state.userUniqueId = payload.data
        state.loading = false;
      })
      .addCase(getUserUniqueId.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(getProfileDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfileDetail.fulfilled, (state, { payload }) => {
        state.getProfileData = payload.data
        state.loading = false;
      })
      .addCase(getProfileDetail.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
    },
});


export default userAuthSlice.reducer;
