import React, { useEffect, useRef } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { useCallback, useState } from "react";
import { Particles } from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import axios from "axios";
import toast from "react-hot-toast";

export default function Banner() {
  const settings = {
    dots: true,
    fade: true,
    nav: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: false,
    swipeToSlide: false,
    speed: 0, //
    beforeChange: (currentSlide, nextSlide) => {
      const currentSlideElement = document.querySelector(
        `.slick-slide[data-index="${currentSlide}"]`
      );

      if (currentSlideElement) {
        currentSlideElement.classList.remove(
          "animate__animated",
          isAnimatingUp ? "animate__fadeInMiddle" : "animate__fadeInDown"
        );

        // Clear any previously set animation delay
        currentSlideElement.style.animationDelay = "1s";
      }

      setIsAnimatingUp(nextSlide < currentSlide); // Determine direction
    },
    afterChange: (currentSlide) => {
      const currentSlideElement = document.querySelector(
        `.slick-slide[data-index="${currentSlide}"]`
      );

      if (currentSlideElement) {
        currentSlideElement.classList.add(
          "animate__animated",
          isAnimatingUp ? "animate__fadeInUp" : "animate__fadeInUp"
        );

        // Apply a 1-second delay
        currentSlideElement.style.animationDelay = "1s";
      }

      // Add or remove class based on the current slide index
      if (currentSlide === 1) {
        overlayRef.current.classList.add("active");
      } else {
        overlayRef.current.classList.remove("active");
      }
    },
  };
  const slider = useRef(null);
  const mainBackgroundRef = useRef(null);
  const overlayRef = useRef(null);
  const [isAnimatingUp, setIsAnimatingUp] = useState(false);

  function handleKeyPress(e) {
    if (!slider.current) return;

    if (e.key === "ArrowDown") {
      slider.current.slickNext(); // Down arrow key moves to the next slide
    } else if (e.key === "ArrowUp") {
      slider.current.slickPrev(); // Up arrow key moves to the previous slide
    }
  }

  let isScrolling = false;

  const scroll = useCallback(
    (e) => {
      if (!slider.current || isScrolling) return;

      if (e.deltaY > 0) {
        slider.current.slickNext();
      } else if (e.deltaY < 0) {
        slider.current.slickPrev();
      }

      isScrolling = true;

      // Reset the isScrolling flag after a delay to allow another scroll
      setTimeout(() => {
        isScrolling = false;
      }, 1000); // Adjust the delay as needed
    },
    [slider]
  );

  useEffect(() => {
    // Add event listeners for scroll and keypress
    window.addEventListener("wheel", scroll, true);
    window.addEventListener("keydown", handleKeyPress, true);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("wheel", scroll, true);
      window.removeEventListener("keydown", handleKeyPress, true);
    };
  }, [scroll]);

  const particlesInit = async (engine) => {
    await loadSlim(engine);
  };

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const { offsetWidth, offsetHeight } = mainBackgroundRef.current;

    const xPos = (clientX / offsetWidth) * 20;
    const yPos = (clientY / offsetHeight) * 20;

    mainBackgroundRef.current.style.backgroundPosition = `${xPos}% ${yPos}%`;
  };

  const validationSchema = Yup.object({
    // name: Yup.string().required("Name is required"),
    // surname: Yup.string().required("Surname is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const initialValues = {
    name: "",
    surname: "",
    email: "",
    country: "",
  };

  const handleAxios = async (data, resetForm) => {
    const url = process.env.REACT_APP_BASE_URL + `/pre-register-user`;

    const response = await axios.post(url, data);
    console.log(response, "axios");
    let payload = response?.data;
    if (payload?.success) {
      toast.success(
        "Thank you for showing interest! You're now part of the Sports@pp community."
      );
    }
    if (!payload.success) {
      toast.success(payload?.message);
    }
    resetForm();
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setIsLoading(false);
    }, 500); // Change the time as needed
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Football Player Management | Soccer Agent - Coming Soon</title>
      </Helmet>
      <div className={`page-loader ${!isLoading ? "hide-this" : ""}`}>
        <div className="spinner-container">
          {" "}
          <div className="css-spinner"></div>
        </div>
      </div>
      <div
        className="main-background-image"
        ref={mainBackgroundRef}
        onMouseMove={handleMouseMove}
      >
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            fpsLimit: 1000,
            interactivity: {
              events: {
                onHover: {
                  enable: true,
                  mode: "connect", // Trigger the spider effect on hover
                },
                resize: true,
              },
              modes: {
                connect: {
                  distance: 300, // Adjust distance as needed for the spider effect
                  radius: 100,
                  links: {
                    opacity: 0.2, // Line opacity between connected particles
                  },
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff33", // Color of the dots
              },
              links: {
                enable: false, // Disable connecting lines by default
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 80, // Number of particles
              },
              opacity: {
                value: 0.3, // Opacity of dots
              },
              shape: {
                type: "circle", // Ensure particles are dots
              },
              size: {
                value: { min: 2, max: 4 }, // Size of dots
              },
            },
            detectRetina: true,
          }}
        />

        <Container>
          <div className="slider-container">
            <Slider {...settings} ref={slider}>
              <div>
                <div className="fist-slide">
                  <img
                    className="animate__animated animate__fadeInDown"
                    src={require("../Assets/images/biglogo.svg").default}
                    alt="Soccer Agent "
                  />
                  <h3 className="animate__animated animate__fadeInUp"></h3>
                  <p className="animate__animated animate__fadeInUp">
                    Coming Soon,Stay Tuned
                    <span className="loader__dot">.</span>
                    <span className="loader__dot">.</span>
                    <span className="loader__dot">.</span>
                  </p>
                </div>
              </div>

              {/* <div>
              <div className="second-slide">
                <h3>
                  OUR APPLICATION IS COOMING SOON
                  <span class="loader__dot">.</span>
                  <span class="loader__dot">.</span>
                  <span class="loader__dot">.</span>
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus mus. Donec quam felis, ultricies nec, pellentesque
                  eu, pretium quis, sem. Nulla consequat massa quis enim. Donec
                  pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
                  In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
                  justo. Nullam dictum felis eu pede mollis pretium. Integer
                  tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean
                  vulputate eleifend tellus. Aenean leo ligula, porttitor eu
                </p>
                <h4>
                  <Link to="#">Click here</Link> to Subscribe and be the first
                  to know
                </h4>
              </div>
            </div> */}

              <div>
                <div className="overlay-back"></div>
                <div className="third-slide">
                  {" "}
                  <img
                    src={require("../Assets/images/whitelogo.svg").default}
                  />
                  <h3 className="top-h">
                    Are you a Soccer/Football Player Agent?
                  </h3>
                  <h3>A Game- Changing Platform Awaits You!</h3>
                  <h3>
                    Subscribe here and{" "}
                    <span style={{ color: "#4C8BB4" }}>
                      be the first to know
                    </span>{" "}
                    about our game- changing project that is coming soon for
                    you.
                  </h3>
                  {/* <div className="third-form">
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter here..." />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Surname</Form.Label>
                      <Form.Control type="text" placeholder="Enter here..." />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control type="email" placeholder="Enter here..." />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Subscribe
                    </Button>
                  </Form>
                </div> */}
                  <div className="third-form">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        console.log(values);
                        handleAxios(values, resetForm);
                        setSubmitting(false);
                      }}
                    >
                      <FormikForm>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Name<span> ( optional ) </span>
                          </Form.Label>
                          <Field
                            type="text"
                            name="name"
                            placeholder="Enter here..."
                            className="form-control"
                          />
                          {/* <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          /> */}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>
                            Surname<span> ( optional ) </span>{" "}
                          </Form.Label>
                          <Field
                            type="text"
                            name="surname"
                            placeholder="Enter here..."
                            className="form-control"
                          />
                          {/* <ErrorMessage
                            name="surname"
                            component="div"
                            className="text-danger"
                          /> */}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>
                            Country<span> ( optional ) </span>
                          </Form.Label>
                          <Field
                            type="text"
                            name="country"
                            placeholder="Enter here..."
                            className="form-control"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Email Address</Form.Label>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Enter here..."
                            className="form-control"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                          Subscribe
                        </Button>
                      </FormikForm>
                    </Formik>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </Container>
        <div className="overlay-open" ref={overlayRef}></div>
      </div>
    </>
  );
}
