import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// signup for action
export const userSighUpAction = createAsyncThunk("userSighUpAction//user/register", async (data) => {
    const response = await api.post("/user/register", data);
    return response.data;
  }
);

// otp-verification
export const userOtpVerification = createAsyncThunk("userOtpVerification/user/verifyOtp", async(data) => {
  const response = await api.post("/user/verifyOtp", data) 
  return response.data
})

// resend otp verification
export const userResendOtpVerification = createAsyncThunk("userResendOtpVerification/user/resendOtp", async(data) => {
  const response = await api.post("/user/resendOtp", data)
  return response.data
})


// get uniqueId
export const getUserUniqueId = createAsyncThunk("getUserUniqueId/user/genUniqueId", async() => {
  const response = await api.get("/user/genUniqueId")
  return response.data
})

// create profile
export const createAgentProfile = createAsyncThunk("createAgentProfile/user/createProfile", async(data) => {
  const response = await api.post("/user/createProfile", data)
  return response.data
})

// login action
export const userLoginAction = createAsyncThunk("userLoginAction/user/login", async (data) => {
  const response = await api.post("/user/login", data)
  return response.data
})

// forgot-password action
export const forgotPasswordAction = createAsyncThunk("forgotPasswordAction/user/forgotPassword", async (data) => {
  const response = await api.post("/user/forgotPassword", data)
  return response.data
})

// reset-password
export const resetPassword = createAsyncThunk("resetPassword/user/resetPassword", async (data) => {
  const response = await api.post("/user/resetPassword", data)
  return response.data
})

// social signup/login
export const socailSignupandLogin = createAsyncThunk("socailSignupandLogin/user/socialLogin", async (data) => {
  const response = await api.post("/user/socialLogin", data)
  return response.data
})

// change password
export const changePassword = createAsyncThunk("changePassword/user/changePassword", async (data) => {
  const response = await api.patch("/user/changePassword", data)
  return response.data
})

// profile action
export const getProfileDetail = createAsyncThunk("getProfileDetail/user/getProfile", async (data) => {
  const response = await api.get("/user/getProfile", data)
  return response.data
})
