import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// create Player
export const createPlayerAction = createAsyncThunk("createPlayerAction/user/createPlayer", async (data) => {
    const response = await api.post("/user/createPlayer", data);
    return response.data;
  }
);

// edit Player
export const editPlayerAction = createAsyncThunk("editPlayerAction/user/editPlayer", async (data) => {
    const response = await api.put("/user/editPlayer", data);
    return response.data;
  }
);

// get player detail by id

export const getPlayerDataById = createAsyncThunk('getPlayerDataById/shop/id/product',async ({ id, shopId }, thunkAPI) => {
  const response = await api.get(`/user/getPlayerById`, {
    params: {
      playerId: id,
    },
  });
  return response.data;
}
);

// get  Players list
export const getPlayerListAction = createAsyncThunk("getPlayerListAction/user/user/getMyPlayer", async (data) => {
  const response = await api.get("user/getMyPlayer", data);
  return response.data;
}
);
